export const sgHomepageContentIconTiles = [
    {
        icon: 'workplace-injury',
        title: 'Injured at work',
        blurb: `<p>Have you or a family member suffered a work-related injury or illness? We can help you understand your rights and entitlements when making a Workers' Compensation claim.</p>`,
        tileLayout: 'featured_layout',
        linkUrl: '/',
        linkTxt: 'Learn more',
    },
    {
        icon: 'traffic-accident',
        title: 'Injured on the road',
        blurb: `<p>If you or a family member is injured on the road in a motor vehicle, public transport, or cycling accident, it is important to get the right advice about a TAC claim. We can help.</p>`,
    },
    {
        icon: 'public-liability',
        title: 'Injured in a public place',
        blurb: `<p>Have you or a family member been injured in a "slip and trip" in a public place? It is important to get the right advice, support and assistance. We can help.</p>`,
    },
];

export default sgHomepageContentIconTiles;
