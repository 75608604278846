import React, { useEffect } from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import TileContainer from '~2-components/TileContainer/TileContainer';
import TileContainerKontent from '~3-data-components/TileContainerKontent';
import sgContentIconTiles from '~data/sgContentIconTiles';
import sgLeftAlignedContentIconTiles from '~data/sgLeftAlignedContentIconTiles';
import sgSidewaysContentIconTiles from '~data/sgSidewaysContentIconTiles';
import sgNumberedContentIconTiles from '~data/sgNumberedContentIconTiles';
import sgHomepageContentIconTiles from '~data/sgHomepageContentIconTiles';

import ContentIconTile from '~2-components/ContentIconTile/ContentIconTile';

const tileContainerEg = `import TileContainer from '~2-components/TileContainer/TileContainer';

<TileContainer
    columns = 'single_column | two_columns | three_column' //Required, default is 'three_columns'. Kontent option.
    homepageLayout = boolean // true will make the first tile a featured tile, and span the full first row
>
    // Children tiles go here.
</TileContainer>
`;

const SgTileContainer = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allTileContainers = useStaticQuery(
        graphql`
            query SGTileContainer {
                allKontentItemTileContainer {
                    nodes {
                        system {
                            id
                        }
                        elements {
                            number_of_columns {
                                value {
                                    codename
                                }
                            }
                            number_the_tiles {
                                value {
                                    codename
                                }
                            }
                            tile_layout {
                                value {
                                    codename
                                }
                            }
                            tiles {
                                modular_content {
                                    system {
                                        id
                                        type
                                    }
                                }
                            }
                        }
                    }
                }
            }
    `);

    const allContainers = allTileContainers.allKontentItemTileContainer.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Tile Container</h1>
                    <h2>How to use</h2>
                    <CodeBlock
                        code={tileContainerEg}
                    />

                    <h2>Example - One Column</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <TileContainer
                        columns="single_column"
                    >
                        {sgContentIconTiles.map(tile => {
                            return (
                                <ContentIconTile
                                    key={tile.title}
                                    icon={tile.icon}
                                    title={tile.title}
                                    blurb={tile.blurb}
                                />
                            );
                        })}
                    </TileContainer>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>Two Columns</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <TileContainer
                        columns="two_columns"
                    >
                        {sgContentIconTiles.map(tile => {
                            return (
                                <ContentIconTile
                                    key={tile.title}
                                    icon={tile.icon}
                                    title={tile.title}
                                    blurb={tile.blurb}
                                />
                            );
                        })}
                    </TileContainer>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>Three Columns</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <TileContainer>
                        {sgContentIconTiles.map(tile => {
                            return (
                                <ContentIconTile
                                    key={tile.title}
                                    icon={tile.icon}
                                    title={tile.title}
                                    blurb={tile.blurb}
                                />
                            );
                        })}
                    </TileContainer>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>Left Aligned Layout</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <TileContainer>
                        {sgLeftAlignedContentIconTiles.map(tile => {
                            return (
                                <ContentIconTile
                                    key={tile.title}
                                    icon={tile.icon}
                                    title={tile.title}
                                    blurb={tile.blurb}
                                    tileLayout={tile.tileLayout}
                                />
                            );
                        })}
                    </TileContainer>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>Sideways Layout</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <TileContainer
                        columns="single_column"
                    >
                        {sgSidewaysContentIconTiles.map(tile => {
                            return (
                                <ContentIconTile
                                    key={tile.title}
                                    icon={tile.icon}
                                    title={tile.title}
                                    blurb={tile.blurb}
                                    tileLayout={tile.tileLayout}
                                />
                            );
                        })}
                    </TileContainer>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>Numbered Tiles</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <TileContainer
                        columns="two_columns"
                    >
                        {sgNumberedContentIconTiles.map(tile => {
                            return (
                                <ContentIconTile
                                    key={tile.title}
                                    icon={tile.icon}
                                    title={tile.title}
                                    blurb={tile.blurb}
                                    tileLayout={tile.tileLayout}
                                    step={tile.step}
                                />
                            );
                        })}
                    </TileContainer>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>Homepage Layout</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <TileContainer
                        columns="two_columns"
                        homepageLayout
                    >
                        {sgHomepageContentIconTiles.map(tile => (
                            <ContentIconTile
                                key={tile.title}
                                icon={tile.icon}
                                title={tile.title}
                                blurb={tile.blurb}
                                tileLayout={tile.tileLayout}
                                linkUrl={tile.linkUrl}
                                linkTxt={tile.linkTxt}
                            />
                        ))}
                    </TileContainer>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>All LIVE Kontent tile containers</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    {allContainers.map((item, i) => <TileContainerKontent key={i} {...item}/>)}
                </div>
            </section>
        </SgLayout>
    );
};

export default SgTileContainer;
